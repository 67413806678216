.panel {
  position: relative;
  padding: 0.277778rem;
  border: 0.055556rem solid transparent;
}

.panel>img {
  width: 0.277778rem;
  height: 0.277778rem;
  position: absolute;
}

.panel>img.top-left {
  top: -0.027778rem;
  left: -0.027778rem;
}

.panel>img.top-right {
  top: -0.027778rem;
  right: -0.027778rem;
}

.panel>img.bottom-left {
  bottom: -0.027778rem;
  left: -0.027778rem;
}

.panel>img.bottom-right {
  bottom: -0.027778rem;
  right: -0.027778rem;
}