.chart-container {
  position: relative;
}

.chart-container .download-icon {
  display: none;
  cursor: pointer;
  position: absolute;
  top: .2rem;
  right: .2rem;
}

.chart-container:hover .download-icon {
  display: block;
}